import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import StyleOriginsSnippet from '../../../snippets/style-origins.mdx';
export const _frontmatter = {
  "title": "Verify Custom UI examples",
  "category": "Customization"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sample 1: additional content on the right`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.729166666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABQUlEQVQoz31Si26DMAzk/7+v0qSJTX2Nqg2vkARCXjc5kLZh7YxOxsZcLraLslT4+Dhjt9ujuki0nUPdGDStjaCYfMqRfw2qNygYM7gxjettAqsNut7C2oB5dtDaYpoMzOxizlq/IuRwAXxYDiuSkqaz8ZSeW5ApJdH3Hdq2hZAjFgsr/pqQLgoqttKJ0HsPpUY455ZiDTifE4WQx0KshHeFa38SoRADlFKQUmIax6iM8mX5icNhj+PxGL9RLgT/UPiO8FQxfB8qnH8qMMaiIuc8vsoLzicGzgcIoeKhztn/CbfXWbpHCgO6BmgY0NYB0/h8ZZv3cEtIKqmHBIoTiDQhPmEZ1L2HD8J8KHyQEfNsMsJEAOTxm6FYcO7WYv+0Iq9XZbs29H9USIudwGoNPswP8Py953rJ8RQvvm40rkzjF8DEVXvabii6AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Right sidebox sample",
          "title": "Right sidebox sample",
          "src": "/static/90d49e4f0e165287b40f964623a2d441/e5715/custom-html-example-sidebox-right.png",
          "srcSet": ["/static/90d49e4f0e165287b40f964623a2d441/8514f/custom-html-example-sidebox-right.png 192w", "/static/90d49e4f0e165287b40f964623a2d441/804b2/custom-html-example-sidebox-right.png 384w", "/static/90d49e4f0e165287b40f964623a2d441/e5715/custom-html-example-sidebox-right.png 768w", "/static/90d49e4f0e165287b40f964623a2d441/4ad3a/custom-html-example-sidebox-right.png 1152w", "/static/90d49e4f0e165287b40f964623a2d441/71c1d/custom-html-example-sidebox-right.png 1536w", "/static/90d49e4f0e165287b40f964623a2d441/2ed34/custom-html-example-sidebox-right.png 1776w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<header class="header-wrapper">
  <img
    class="header-logo"
    src="https://demo-cool-energy-react.netlify.app/logo-text.png"
    alt="logo"
  />
</header>
<div class="content-wrapper">
  <div class="criipto-verify">
    <criipto-verify />
  </div>
  <div class="sidebox">
    <h1 class="sidebox-heading">Logging in with eID</h1>
    <p>
      We use eIDs to confirm your identity and prevent any unauthorized sign-ins
      in your name.
    </p>
    <ul class="sidebox-links">
      <li class="sidebox-link"><a href="#">Need help logging in?</a></li>
      <li class="sidebox-link">
        <a href="#">Business customer? Login with MitID Erhverv</a>
      </li>
      <li class="sidebox-link">
        <a href="#">Log in with Norwegian BankID instead</a>
      </li>
    </ul>
  </div>
</div>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;600;700&display=swap');

body {
  position: relative;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: #edeefb;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.header-wrapper {
  padding: 20px;
  margin-bottom: 3vh;
}

.header-logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: auto;
  padding: 20px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 800px;
  margin-top: 20px;
}

.criipto-verify,
.sidebox {
  margin: 5px 0;
  flex: 1;
}

.sidebox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}

.sidebox-heading {
  margin-top: 0;
}

.sidebox-links {
  padding: 0;
  list-style: none;
}

.sidebox-link {
  margin-bottom: 20px;
}

.sidebox-link a {
  text-decoration: none;
  color: #604fec;
  font-weight: bold;
}

.sidebox-link a:hover {
  text-decoration: underline;
}

/* Font above the Criipto Verify frame */
.broker header,
.broker #headline {
  font-family: 'IBM Plex Sans', sans-serif;
}

@media only screen and (min-width: 601px) {
  .header-wrapper {
    padding-left: 40px;
  }

  .header-logo {
    margin-bottom: 20px;
    height: 4vh;
  }

  .content-wrapper {
    flex-direction: row;
    align-items: stretch;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 93vh;
  }

  .sidebox {
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .criipto-verify {
    margin-right: 25px;
    margin-left: 25px;
  }
}

@media screen and (min-width: 992px) {
  .header-logo {
    height: 50px;
    margin-bottom: 20px;
  }

  .content-wrapper {
    max-width: 1200px;
  }

  .header-wrapper {
    margin-bottom: 60px;
  }
}
`}</code></pre>

    <StyleOriginsSnippet mdxType="StyleOriginsSnippet" />
    <h2>{`Sample 2: additional content on the left`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.479166666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABuUlEQVQoz32Sz2sTQRiG8wdWbShphcZuSJoWS9uoLeZHBT0EgnqJeJMc7cGjB08eBP8AwUBitRqXisSws7OTbdpNN00emV2z2bTgwMsMHzMP73zvl1i7JcilbApZyb2cZMeQ6JqxaAdK3xB8/zrif2symUTnhH6cSdqsL9vkVyTrKQ26CvSDy1IqhBBIKen1eliWxWg0moMmpm7CvYexaAWQaT29IPjx7RK4wDRPApjruiilAqDv+/NA/XjbkLx86vL8yW+ePRYcFFTkcgYcYlk27XabVqtFs9kMoFNYBLxzU7B526Za6nM/p8gvS+6uSozk7MvHR6GL4dCnVqtRLBapVCqYphnUx+PxrIcamF+xebjlsL/psJuRbKVnwcSByulTrVYplUqUy2U6nU4EvObwUUGxt+FwsKvYXpPo+tVQTgfnvD48pNFoUK/X6Xa71x1qJ9klO4Bo7WTCpOOhhGMzxjsdcHE2QCkHz/MiZ3ElwoTnlfnXP63VBYuTDvwSf3jx5h2v3r5HKDd0Fgsj1kOLXEqwtxEO9oO8pJC1MZIiGCEN/Hl8Sd9z+fDpCx8/H+Genc/1Lu7wLyCTi37K/VE5AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Left sidebox sample",
          "title": "Left sidebox sample",
          "src": "/static/1440d726d77e1803903f6687dcac6294/e5715/custom-html-example-sidebox-left.png",
          "srcSet": ["/static/1440d726d77e1803903f6687dcac6294/8514f/custom-html-example-sidebox-left.png 192w", "/static/1440d726d77e1803903f6687dcac6294/804b2/custom-html-example-sidebox-left.png 384w", "/static/1440d726d77e1803903f6687dcac6294/e5715/custom-html-example-sidebox-left.png 768w", "/static/1440d726d77e1803903f6687dcac6294/4ad3a/custom-html-example-sidebox-left.png 1152w", "/static/1440d726d77e1803903f6687dcac6294/71c1d/custom-html-example-sidebox-left.png 1536w", "/static/1440d726d77e1803903f6687dcac6294/89557/custom-html-example-sidebox-left.png 1928w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="content-wrapper">
  <div class="infobox">
    <div class="infobox-content">
      <img
        class="infobox-logo"
        src="https://demo-cool-energy-react.netlify.app/logo-white.png"
        alt="logo"
      />
      <div class="infobox-text">
        <h1 class="sidebox-heading">
          Hey! <span>Log in</span> to your Cool Energy profile
        </h1>
        <p>
          Don't have BankID? <br />
          Log in using a one-time code sent to your email or your confirmed
          mobile number.<br /><br />
          Don't have a Cool Energy profile? Open one now by contacting your
          agent or logging in with BankID.
        </p>
      </div>
      <div class="infobox-footer">
        <p>coolenergy.dk · <a href="#">Cookie Policy and Handling of Personal Data</a></p>
        <p>© Cool Energy Inc. 2022-2023</p>
      </div>
    </div>
  </div>
  <div class="criipto-verify">
    <div class="inner">
      <criipto-verify />
    </div>
  </div>
</div>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;600;700&display=swap');

body {
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0;
  background-color: white;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.infobox {
  display: flex;
  justify-content: center;
  background-color: #6050ed;
  color: white;
}

.criipto-verify,
.infobox {
  padding: 10px 30px;
}

.infobox span {
  color: #fffdac;
}

.infobox-logo {
  display: none;
}

.infobox-footer {
  display: none;
}

.criipto-verify {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Font above the Criipto Verify frame */
.broker header,
.broker #headline {
  font-family: 'IBM Plex Sans', sans-serif;
}

@media only screen and (min-width: 601px) {
  .content-wrapper {
    flex-direction: row;
    align-items: stretch;
    min-height: 100vh;
    gap: 20px;
  }

  .infobox {
    min-height: 100vh;
  }

  .criipto-verify {
    flex: 2 1 50%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 2rem;
    padding: 0 2rem;
  }

  .infobox {
    display: flex;
    flex: 1 2 33%;
    padding: 0 2rem;
    align-items: center;
  }

  .infobox-logo {
    display: block;
    width: 8rem;
  }

  .infobox-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: calc(100vh - 50px);
  }

  .infobox-footer {
    display: block;
    font-size: 0.8rem;
    padding: 0;
  }

  .infobox-footer p {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .infobox-footer a {
    text-decoration: underline;
    color: white;
  }
}

@media screen and (min-width: 992px) {
  .infobox {
    padding: 0 12rem;
  }

  .infobox-logo {
    width: 12rem;
  }

  .infobox-footer {
    margin-bottom: 20px;
  }
}
`}</code></pre>
    <StyleOriginsSnippet mdxType="StyleOriginsSnippet" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      